/* Ensure the section is positioned correctly */
.testimonials_section {
    position: relative; /* Required for positioning the dots and shapes */
   
}

/* Dots container to position it on the right side */
.slick-dots-container {
    position: absolute;
    right: 10px; /* Adjust the distance from the right edge */
    top: 50%;
    /* transform: translateY(-50%);  */
    /* Centers the dots vertically */
    z-index: 10; /* Ensure dots are above other content */
    
}
.person_name {
    
        font-size: 20PX;
        font-weight: 700;
        color: white;
    
}
.testimonials_image_content_wrapper {
    color: #9b9b9e;
    margin-left: 124px;
}

/* Style the dots */
.custom-dots li button:before {
    color: #0ee30d; /* Dot color */
    font-size: 15px; /* Dot size */
    margin-top: 5px;
}

.custom-dots li.slick-active button:before {
    color: #0ee30d; /* Color for the active dot */
}

/* Optional: Style the dots container */
.slick-dots {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin-top: 10px;
}

.slick-dots li {
    margin: 5px 0; /* Spacing between dots */
}
.slick-slide {
    padding: 0 15px; /* Adjust the value as needed */
}

.testimonials_section .item {
    margin: 0 15px; /* Adjust the value as needed */
}


