/* StatisticsSection.css */

.span_wrapper {
    display: flex;
    align-items: center; /* Aligns items vertically centered */
}

.number {
    display: inline-block; /* Ensures the number and K+ are inline */
}

.plus {
    margin-left: 4px; /* Adds space between the number and K+ */
    font-size: 0.8em; /* Optional: adjust the size of K+ relative to the number */
}
