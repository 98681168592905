.custom-modal-dialog {
    max-width: 60%;
    width: auto;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.custom-modal-content {
    height: 100%;
}

.modal-body-custom {
    display: flex;
}
.zoomed {
    transform: scale(1.5); /* Adjust the scale as needed */
    transition: transform 0.3s ease; /* Smooth transition */
    cursor: zoom-out; /* Change cursor to indicate zoom out */
    z-index: 10; /* Ensure the zoomed image is above other elements */
}

.modal-body-custom img {
    width: 80%;
    margin-right: 1rem;
}
.modal-content {
max-width: 173vh;

}
/* Media query for mobile devices */
@media (max-width: 768px) {
    .custom-modal-dialog {
        max-width: 95%; /* Increase width on smaller screens */
        margin: 10px auto; /* Reduce margin on smaller screens */
    }

    .custom-modal-content {
        max-height: 95vh; /* Allow more height on mobile */
    }

    .modal-body-custom {
        padding: 10px; /* Reduce padding on mobile */
    }

    .modal-body-custom img {
        margin-bottom: 0.5rem; /* Reduce margin on mobile */
    }
}
.slick-dots {
    bottom: -30px; /* Adjust this value as needed */
}

.slick-dots li button:before {
    font-size: 15px; /* Adjust this value as needed */
    color: #08f03b; /* Adjust this color as needed */
}

.slick-dots li.slick-active button:before {
    color: #0bf06a; /* Adjust this color as needed */
}


ul.slick-dots{
    margin: 0px;

}

